<template>
  <div id="view-article-detail" class="p-2">
    <Detail :session="session" :product="item"></Detail>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import Detail from "@/Druware.Web.Shared/components/ProductDetail";

const url = "/api/product/";

export default {
  name: "view.product",
  components: {
    Detail
  },
  data: function() {
    return {
      session: {},
      item: null
    };
  },
  methods: {},
  created() {
    this.session = this.$attrs.session;
    API.get(
      url,
      this,
      this.$route.params.short,
      function(obj, data) {
        obj.item = data;
        window.document.title =
          "Druware - " + (obj.item && obj.item.name ? obj.item.name : "");
      },
      function(obj, err) {
        obj.$buefy.toast.open({
          duration: 5000,
          message: err,
          position: "is-bottom",
          type: "is-danger"
        });
      }
    );
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#view-article-detail {
  .left {
    text-align: left;
  }

  .icon-count {
    width: 48px;
  }
}
</style>
